<template>
  <div>
    <h2 class="pb-0 pt-2 pl-5 pr-5">Laden Sie stattdessen entweder ein Bild oder eine PDF mit Ihrem QR-Code hoch</h2>
    <input type="file" ref="file" @change="handleUpload()" class="d-none" />
    <button class="btn btn-primary btn-lg mt-3" @click="clickFileInput()" v-if="!isLoading()">QR Code Datei hochladen</button>
    <div class="alert alert-danger mt-3" v-if="isWrongType()">Dieser Datei-Typ wird nicht unterstützt. Bitte wählen Sie eine andere Datei.</div>
    <div class="alert alert-danger mt-3" v-if="isNoQrCodeDetected()">Es konnte kein QR Code erkannt werden. Bitte probieren Sie es erneut.</div>
    <h1 v-if="isLoading()">Wird verarbeitet...</h1>
    <div>
      <small>oder</small>
      <div>
        <button class="btn btn-secondary mb-4" @click="setScanMode('visual')">QR Code scannen</button>
      </div>
    </div>
  </div>
</template>
<script>

import QrCodeUploadService from "../services/QrCodeUploadService";

const qrCodeUploadService = new QrCodeUploadService();

export default {
  created() {
    qrCodeUploadService.init();
    qrCodeUploadService.setParent(this);
  },
  data() {
    return {
      loading: false,
      noQrCodeDetected: false,
      wrongType: false
    }
  },
  methods: {
    setScanMode(scanMode) {
      this.$parent.setScanMode(scanMode);
    },
    isLoading() {
      return qrCodeUploadService.isLoading();
    },
    isWrongType() {
      return qrCodeUploadService.isWrongType();
    },
    isNoQrCodeDetected() {
      return qrCodeUploadService.isNoQrCodeDetected();
    },
    setScannedData(data) {
      this.$parent.setScannedData(data);
    },
    clickFileInput() {
      const el = this.$refs.file;
      if (el.onclick) {
        el.onclick();
      } else if (el.click) {
        el.click();
      }
    },
    handleUpload() {
      qrCodeUploadService.handleUpload(this.$refs.file.files[0]);
    }
  }
}
</script>