if (!('createImageBitmap' in window)) {
    window.createImageBitmap = async function(blob) {
        // eslint-disable-next-line
        return new Promise((resolve,reject) => {
            let img = document.createElement('img');
            img.addEventListener('load', function() {
                resolve(this);
            });
            img.src = URL.createObjectURL(blob);
        });
    }
}

import jsQR from "jsqr";
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
class QrCodeUploadService extends Vue {

    data() {
        return {
            scriptLoadCounter: 0,
            loading: false,
            wrongType: false,
            noQrCodeDetected: false,
            uploadedFile: null,
            scannedData: null,
            parent: null,
            pwaMode: false
        }
    }

    init() {
        this.scriptLoadCounter = 0;
        this.loading = false;
        this.wrongType = false;
        this.noQrCodeDetected = false;
    }

    setPwaMode(pwaMode) {
        this.pwaMode = pwaMode;
    }

    setParent(e) {
        this.parent = e;
    }

    setUploadedFile(file) {
        this.uploadedFile = file;
    }

    getUploadedFile() {
        return this.uploadedFile;
    }

    setLoading(loading) {
        this.loading = loading;
    }

    isLoading() {
        return this.loading;
    }

    setWrongType(wrongType) {
        this.wrongType = wrongType;
    }

    isWrongType() {
        return this.wrongType;
    }

    setNoQrCodeDetected(noQrCodeDetected) {
        this.noQrCodeDetected = noQrCodeDetected;
    }

    isNoQrCodeDetected() {
        return this.noQrCodeDetected;
    }

    getScannedData() {
        return this.scannedData;
    }

    setScannedData(data) {
        this.parent.setScannedData(data);
        this.scannedData = data;
    }

    handleUpload(file) {
        this.init();
        this.setLoading(true);
        this.setUploadedFile(file);
        if(['application/pdf'].includes(this.getUploadedFile().type)) {
            this.handlePDF();
        } else if(['image/png','image/jpg','image/jpeg'].includes(this.getUploadedFile().type)) {
            this.handleImage();
        } else {
            this.setLoading(false);
            this.setWrongType(true);
            if(this.pwaMode) {
                alert("Dieser Datei Typ wird leider nicht unterstützt. Es können JPG, PNG und PDF Dateien verwendet werden.");
            }
        }

    }

    handlePDF() {
        const scriptLoadedCheck = document.body.getAttribute('pdf-scripts-loaded');
        if("true" !== scriptLoadedCheck) {
            this.addScript('js/pdf.js', this.watchPdfScriptLoading);
            this.addScript('js/pdf.worker.js', this.watchPdfScriptLoading);
        } else {
            this.finalizePDF();
        }
    }

    handleImage() {
        var e = this;

        createImageBitmap(this.getUploadedFile())
            .then(bmp  => {
                const canvas = document.createElement('canvas');

                const ratioSize = e.calculateAspectRatioFit(bmp.width, bmp.height, 1000, 1000);
                canvas.width = ratioSize.width;
                canvas.height = ratioSize.height;

                const ctx = canvas.getContext('2d');

                ctx.drawImage(bmp, 0, 0, bmp.width, bmp.height, 0, 0, canvas.width, canvas.height);

                const qrCodeImageFormat = ctx.getImageData(0,0,canvas.width,canvas.height);
                const qrDecoded = jsQR(qrCodeImageFormat.data, qrCodeImageFormat.width, qrCodeImageFormat.height);

                if(qrDecoded) {
                    e.setScannedData(qrDecoded.data);
                } else {
                    this.setNoQrCodeDetected(true);
                    this.setLoading(false);
                    if(this.pwaMode) {
                        alert("Es konnte kein QR Code in dem Bild erkannt werden. Bitte versuchen Sie es erneut.");
                    }
                }

            });
    }

    finalizePDF() {
        var e = this;
        const file = this.getUploadedFile();

        if(typeof pdfjsLib !== 'undefined') {
            const fileData = URL.createObjectURL(file);
            // eslint-disable-next-line
            var loadingTask = pdfjsLib.getDocument(fileData);
            loadingTask.promise.then(function(pdf) {
                pdf.getPage(1).then(function(page) {
                    var scale = 1.5;
                    var viewport = page.getViewport({scale: scale});
                    var canvas = document.createElement('canvas');
                    var context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;
                    var renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };
                    var renderTask = page.render(renderContext);
                    renderTask.promise.then(function () {
                        const qrCodeImageFormat = context.getImageData(0,0,canvas.width,canvas.height);
                        const qrDecoded = jsQR(qrCodeImageFormat.data, qrCodeImageFormat.width, qrCodeImageFormat.height);
                        if(qrDecoded) {
                            e.setScannedData(qrDecoded.data);
                        } else {
                            e.setNoQrCodeDetected(true);
                            e.setLoading(false);
                            if(this.pwaMode) {
                                alert("Es konnte kein QR Code in dem PDF erkannt werden. Bitte versuchen Sie es erneut.");
                            }
                        }
                    });
                });
            }, function (reason) {
                console.error(reason);
            });
        }

    }

    watchPdfScriptLoading() {
        this.scriptLoadCounter++;
        if(this.scriptLoadCounter >= 2) {
            document.body.setAttribute('pdf-scripts-loaded', true);
            this.finalizePDF();
        }
    }

    addScript( src, callback ) {
        var s = document.createElement( 'script' );
        document.body.appendChild( s );
        s.src = src;
        s.onload=callback;
    }

    emitEvent(event, data) {
        if(null !== this.parent) {
            this.parent.$emit(event, data);
        }
    }

    calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {

        var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        return { width: srcWidth*ratio, height: srcHeight*ratio };
    }

}

export default QrCodeUploadService;