<template>
  <div>
    <qrcode-vue v-if="decodedString" :value="decodedString" :size="size()" class="qr-code-element" />
    <div v-if="!decodedString">
      <img src="images/qrcode_demo.svg" id="demo-qr-code" />
    </div>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'

export default {
  props: ['decodedString', 'qrCodeSize'],
  data() {
    return {
      size: function() {
        return this.qrCodeSize;
      },
    }
  },
  methods: {
    getQrCodeType() {
      return this.$parent.getQrCodeType();
    }
  },
  components: {
    QrcodeVue,
  },
}
</script>