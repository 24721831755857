<template>
  <div id="outer-body" :class="getOuterBodyClass()">
    <div class="container text-center" id="outer-container">
      <div class="row h-100">
        <div class="col-12 col-lg-7 my-lg-auto">
          <div v-if="isPinCodeCorrect()">
            <QrCodeReader v-if="scanMode === 'visual'" :scanned-data="decodedString" />
            <QrCodeUpload v-if="scanMode === 'file'" ref="qrUploadComponent" />
            <small>Hinweis: Der PIN Code kann durch 5-maliges schnelles Tippen in die linke untere Ecke zurückgesetzt werden.</small>
          </div>
          <div v-if="!showKeyPad && !isPinCodeCorrect()">
            <QrCodeViewer :decoded-string="decodedString" :qr-code-size="qrCodeSize" />
          </div>
          <div v-if="showKeyPad">
            <ProtectionManager :stored-pin="storedPinCode" />
          </div>
        </div>
        <div class="col-12 col-lg-5 d-flex">
          <div class="desc-text" v-if="!isPinCodeCorrect()">
            <div class="cwa-text" v-if="getQrCodeType() === 'cwa'">
              <img src="images/coronawarnapp_logo.svg" id="coronawarnapp_logo" />
              <h1 style="color: #02719a;">CHECKEN SIE EIN.</h1>
              <h1 style="color: #02719a;">STOPPEN SIE DAS VIRUS.</h1>
              <p style="color: #404040; font-weight: 700;">Nutzen Sie die Corona-Warn-App! Scannen Sie den QR-Code und tragen Sie aktiv dazu bei, mögliche Infektionsketten schnell und effektiv zu durchbrechen.</p>
            </div>
            <div class="luca-text" v-if="getQrCodeType() === 'luca'">
              <img src="images/luca_logo.svg" id="luca_logo" />
              <h1 style="color: #000000;">Wir nutzen luca</h1>
              <p>1. App downloaden</p>
              <p>2. Kontaktdaten einmalig angeben</p>
              <p>3. Einchecken</p>
            </div>
            <div class="qrcodeticket-text" v-if="getQrCodeType() === 'qrcodeticket'">
              <img src="images/renzelreg_logo.svg" id="qrcodeticket_logo" />
              <h1 style="color: #000000;">Wir nutzen RenzelREG</h1>
              <p>1. QR Code scannen</p>
              <p>2. Kontaktdaten einmalig angeben</p>
              <p>3. Einchecken</p>
            </div>
            <div class="qrcodeticket-text" v-if="getQrCodeType() === null">
              <h1 style="color: #000000;">Nutzen Sie jetzt unsere Kontaktnachverfolgung</h1>
            </div>
            <div v-if="getQrCodeType() === false">
              <h1 style="color: #000000;">Bitte richten Sie einen PIN ein und scannen Sie anschließend einen QR Code</h1>
              <button class="btn btn-lg btn-primary mt-3" v-on:click="togglePinCodeField()">Jetzt einrichten</button>
            </div>
          </div>
        </div>
      </div>
      <footer class="text-center">
        <div class="promo-link" @click="showPromoBanner">vkf.de/corona</div>
        Made with <span>&#10084;</span> by VKF Renzel | <router-link to="/impressum">Impressum</router-link> - <router-link to="/datenschutz">Datenschutz</router-link>
      </footer>
      <button id="button-fullscreen" v-if="isPinCodeCorrect()" v-on:click="toggleFullScreen()" class="btn btn-secondary"><b-icon-window></b-icon-window></button>
      <button id="button-key" v-on:click="togglePinCodeField()" class="btn btn-secondary"><b-icon-key></b-icon-key></button>
      <button class="btn btn-warning update-btn" v-if="updateExists" @click="refreshApp">Die Seite muss aktualisiert werden. Bitte klicken Sie hier.</button>
      <div class="promo-popup-wrapper" v-if="showPromo" @click="hidePromoBanner">
        <div class="promo-popup">
          <div class="promo-inner">
            <h4>Entdecken Sie jetzt die Produktwelt der VKF</h4>
            <qrcode-vue :value="promoLink" />
            <h4>vkf.de/corona</h4>
          </div>
        </div>
      </div>
      <div class="reset-hidden-btn" @click="resetPin()">

      </div>
    </div>
  </div>
</template>
<script>
import QrCodeReader from './components/QrCodeReader';
import QrCodeViewer from './components/QrCodeViewer';
import ProtectionManager from "./components/ProtectionManager";
import QrcodeVue from 'qrcode.vue';
import QrCodeUpload from "./components/QrCodeUpload";
import QrCodeUploadService from "./services/QrCodeUploadService";

const qrCodeUploadService = new QrCodeUploadService();

export default {
  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    });

    qrCodeUploadService.setParent(this);
    qrCodeUploadService.setPwaMode(true);

  },
  data() {
    return {
      decodedString: null,
      pinCodeInserted: '',
      storedPinCode: false,
      showKeyPad: false,
      qrCodeSize: 400,
      isFullScreen: false,
      refreshing: false,
      registration: null,
      updateExists: false,
      promoLink: 'https://vkf.de/corona',
      showPromo: false,
      hidePromoTimer: null,
      scanMode: 'visual',
      resetPinCounter: 0,
      resetPinTimer: null
    }
  },
  mounted() {
    this.loadLocalStorage();
    this.setQrCodeSize();
    window.onresize = this.setQrCodeSize;

    const e = this;

    navigator.serviceWorker.onmessage = function(event) {
      e.setByServiceWorkerPost(event);
    };

    this.$on('updateScannedData', function(data) {
      this.setScannedData(data);
    });

  },
  components: {
    QrCodeUpload,
    ProtectionManager,
    QrCodeReader,
    QrCodeViewer,
    QrcodeVue
  },
  methods: {
    loadLocalStorage() {
      if(typeof window.localStorage !== 'undefined') {
        const QR_CODE_DATA = window.localStorage.getItem('SAVE_QR_CODE_DATA');
        if(typeof QR_CODE_DATA !== 'undefined' && null !== QR_CODE_DATA) {
          this.decodedString = QR_CODE_DATA;
        }
        const STORED_PIN_CODE = window.localStorage.getItem('STORED_PIN_CODE');
        if(typeof STORED_PIN_CODE !== 'undefined' && null !== STORED_PIN_CODE) {
          this.storedPinCode = STORED_PIN_CODE;
        }
      }
    },
    setScannedData(data) {
      if(data.trim().length > 0) {
        if(typeof window.localStorage !== 'undefined') {
          window.localStorage.setItem('SAVE_QR_CODE_DATA', data);
        }
        this.pinCodeInserted = '';
        this.showKeyPad = false;
        this.decodedString = data;
        this.scanMode = 'visual';
      }
    },
    setPinCode(code) {
      if(code.length === 4) {
        if(false === this.storedPinCode) {
          if(typeof window.localStorage !== 'undefined') {
            window.localStorage.setItem('STORED_PIN_CODE', code);
          }
          this.storedPinCode = code;
        }
        this.pinCodeInserted = code;
      }
    },
    isPinCodeCorrect() {
      if(typeof window.localStorage !== 'undefined') {
        if(typeof window.localStorage.getItem('STORED_PIN_CODE') !== 'undefined') {
          if (window.localStorage.getItem('STORED_PIN_CODE') === this.pinCodeInserted) {
            this.showKeyPad = false;
            return true;
          }
        }
      }
      return false;
    },
    togglePinCodeField() {
      if(false === this.showKeyPad) {
        if(this.isPinCodeCorrect()) {
          this.showKeyPad = false;
        } else {
          this.showKeyPad = true;
        }
        this.pinCodeInserted = '';
      } else {
        this.showKeyPad = false;
      }
    },
    setQrCodeSize() {
      const clientWidth = document.getElementById('outer-container').clientWidth;
      if(clientWidth < 800) {
        this.qrCodeSize = clientWidth / 1.5;
      } else {
        this.qrCodeSize = clientWidth / 2;
      }
    },
    toggleFullScreen() {
      var elem = document.documentElement;
      if(false === this.isFullScreen) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
        this.isFullScreen = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
        this.isFullScreen = false;
      }
    },
    getQrCodeType() {
      if(null !== this.decodedString) {
        if(this.decodedString.indexOf('coronawarn.app') !== -1) {
          return 'cwa';
        }
        if(this.decodedString.indexOf('luca-app.de') !== -1) {
          return 'luca';
        }
        if(this.decodedString.indexOf('qrcodeticket.de') !== -1) {
          return 'qrcodeticket';
        }
      }
      if(null === this.decodedString || this.decodedString.trim().length === 0) {
        return false;
      } else {
        return null;
      }
    },
    getOuterBodyClass() {
      const qrCodeType = this.getQrCodeType();
      if(false !== qrCodeType) {
        return qrCodeType;
      }
      return null;
    },
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },

    // Called when the user accepts the update
    refreshApp() {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    showPromoBanner() {
      clearTimeout(this.hidePromoTimer);
      this.showPromo = true;
      this.hidePromoTimer = setTimeout(this.hidePromoBanner, 5000);
    },
    hidePromoBanner() {
      this.showPromo = false;
    },
    setScanMode(scanMode) {
      this.scanMode = scanMode;
    },
    resetPin() {
      if(this.resetPinCounter >= 4) {
        if(confirm('Möchten Sie den PIN wirklich zurücksetzen?')) {
          this.storedPinCode = false;
          if(typeof window.localStorage !== 'undefined') {
            window.localStorage.removeItem('STORED_PIN_CODE');
            window.localStorage.removeItem('SAVE_QR_CODE_DATA');
          }
          location.reload();
        }
      } else {
        clearTimeout(this.resetPinTimer);
        this.resetPinTimer = setTimeout(this.resetInitialResetPinCounter, 500);
        this.resetPinCounter++;
      }
    },
    resetInitialResetPinCounter() {
      this.resetPinCounter = 0;
    },
    setByServiceWorkerPost(event) {
      if(typeof event.data !== 'undefined' && typeof event.data.file !== 'undefined') {
        qrCodeUploadService.handleUpload(event.data.file);
      }
    }
  }
}
</script>