<template>
  <div>
    <h2 class="pb-0 pt-3 pl-5 pr-5">Scannen Sie Ihren QR-Code</h2>
    <qrcode-stream v-if="!error" @decode="onDecode" @init="onInit" class="mt-3">
      <h1 v-if="loading">Wird geladen...</h1>
    </qrcode-stream>
    <div class="alert alert-danger mt-3" v-if="error">
      <h1>Fehler</h1>
      <h3>{{error}}</h3>
    </div>
      <small>oder</small>
      <div>
        <button class="btn btn-secondary mb-4" @click="setScanMode('file')">QR Code Datei hochladen</button>
      </div>
  </div>
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  props: ['scannedData'],
  components: {
    QrcodeStream,
  },
  data() {
    return {
      error: false,
      loading: false
    }
  },
  methods: {
    setScanMode(scanMode) {
      this.$parent.setScanMode(scanMode);
    },
    onDecode (decodedString) {
      this.$parent.setScannedData(decodedString);
    },
    async onInit (promise) {
      this.loading = true;
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "Sie müssen den Zugriff auf ihre Kamera erlauben"
        } else if (error.name === 'NotFoundError') {
          this.error = "Es wurde keine Kamera erkannt"
        } else if (error.name === 'NotSupportedError') {
          this.error = "Die Seite wurde über keinen sicheren Kontext geladen (HTTPS)"
        } else if (error.name === 'NotReadableError') {
          this.error = "Ist die Kamera bereits in Verwendung?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "Die verbaute Kamera kann nicht verwendet werden"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "Dieser Browser untersützt dieses Feature leider nicht"
        }
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>