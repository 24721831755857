<template>
  <div class="container text-center mt-5" id="outer-container">
    <router-link class="btn btn-secondary" to="/">Zurück</router-link>
    <h1>Datenschutz</h1>
    <p>Soweit nachstehend keine anderen Angaben gemacht werden, ist die Bereitstellung Ihrer personenbezogenen Daten weder gesetzlich oder vertraglich vorgeschrieben, noch für einen Vertragsabschluss erforderlich. Sie sind zur Bereitstellung der Daten nicht verpflichtet. Eine Nichtbereitstellung hat keine Folgen. Dies gilt nur soweit bei den nachfolgenden Verarbeitungsvorgängen keine anderweitige Angabe gemacht wird. &quot;Personenbezogene Daten&quot; sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.</p>

    <h2>Datenerhebung bei der Nutzung durch Anwender</h2>
    <p>Diese Anwendung stellt einen QR-Code zum Abscannen zur Verfügung. Dieser öffnet auf dem Endgerät des Benutzers eine Anwendung, für die die jeweils angegebenen Datenschutzbestiummungen gelten. Durch das Scannen eines QR-Codes werden in dieser Anwendung keinerlei Daten erhoben.</p>

    <h2>Datenerhebung bei der Pflege durch den Administrator</h2>
    <p>Bei der Anlage eines QR-Codes wird der Inhalt des gescannten QR-Codes ausgelesen und lokal auf dem Endgerät gespeichert, mit dem der Scan geschieht. Die Angabe des Passwortes / PIN-Code wird ebenfalls ausschließlich lokal gespeichert. Eine Übertragung an externe Server geschieht nicht. Alle Daten werden lokal auf dem Endgerät des Administrators gespeichert. Ein Zurücksetzen der Browserdaten bedingt das Löschen der eingestellten Informationen.</p>

    <h2>Server-Logfiles </h2>
    <p>Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Es werden bei jedem Zugriff auf unsere Website Nutzungsdaten durch Ihren Internetbrowser übermittelt und in Protokolldaten (Server-Logfiles) gespeichert. Zu diesen gespeicherten Daten gehören z.B. Name der aufgerufenen Seite, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und der anfragende Provider. Diese Daten dienen ausschließlich der Gewährleistung eines störungsfreien Betriebs unserer Website und zur Verbesserung unseres Angebotes. Eine Zuordnung dieser Daten zu einer bestimmten Person ist nicht möglich.</p>

    <h2>Cookies</h2>
    Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem eines Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht. Wir setzen Cookies zu dem Zweck ein, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.
    Die Daten des hinterlegten QR-Codes werden lokal in einem Cookie gespeichert.

    Die Verarbeitung erfolgt auf Grundlage des § 15 (3) TMG sowie Art. 6 (1) lit. f DSGVO aus dem berechtigten Interesse an den oben genannten Zwecken.

    Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die volle Kontrolle über die Verwendung von Cookies. Durch die Auswahl entsprechender technischer Einstellungen in Ihrem Internetbrowser können Sie die Speicherung der Cookies und Übermittlung der enthaltenen Daten verhindern. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Wir weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.<br><br>
  </div>
</template>
<script>
export default {
}
</script>