<template>
  <div style="min-height: 300px;">
    <div class="card" style="position: absolute; top: 50%; left: 50%; margin-right: -50%; transform: translate(-50%, -50%);">
      <div class="card-body">
        <p class="alert alert-primary" v-if="isPinStored() && isPinCorrect()">Bitte Pin-Code eingeben</p>
        <p class="alert alert-warning" v-if="!isPinStored()">Bitte Pin-Code anlegen</p>
        <p class="alert alert-danger" v-if="!isPinCorrect()">Pin-Code ungültig</p>
        <PincodeInput
            v-model="pinCode"
            placeholder="*"
            :characterPreview=false
        />
      </div>
    </div>
  </div>
</template>
<script>
import PincodeInput from 'vue-pincode-input';

export default {
  props: ['storedPin'],
  components: {
    PincodeInput,
  },
  data() {
    return {
      pinCode: ''
    }
  },
  methods: {
    isPinStored() {
      return false !== this.storedPin;
    },
    isPinCorrect() {
      if(this.pinCode.length === 4) {
        return this.$parent.isPinCodeCorrect();
      }
      return true;
    }
  },
  watch: {
    pinCode: function(pinCode) {
      this.$parent.setPinCode(pinCode);
    }
  }
}
</script>