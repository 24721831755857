<template>
  <div class="container text-center mt-5" id="outer-container">
    <router-link class="btn btn-secondary" to="/">Zurück</router-link>
    <h1>Impressum</h1><h2><br></h2><h2>Gesetzliche Anbieterkennung:
  </h2>VKF Renzel GmbH
    <br>diese vertreten durch die Geschäftsführer Heinz Renzel, Ansgar Hügging, Joachim Ostendorf
    <br>Im Geer 15
    <br>46419 Isselburg
    <br>Deutschland
    <span style="font-size: 1.2rem;"></span><br>
    <br>Telefon: 02874 / 910-0
    <br>Telefax: 02874 / 910-101
    <br>
    <br>GLN: 4011432000007
    <span style="font-size: 1.2rem;"></span><br>
    <br>E-Mail: info@vkf-renzel.de<br>Web: www.vkf-renzel.de<span style="font-size: 1.2rem;"></span><br>
    <br>USt-ID-Nr.: DE 124168415
    <br>eingetragen im Handelsregister des Amtsgerichtes Coesfeld
    <br>Handelsregisternummer HRB 8004
    <span style="font-size: 1.2rem;"></span><br>
    <br>Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeteilgung (OS-Plattform) bereit, aufrufbar unter https://ec.europa.eu/odr.&nbsp;<br><br>
  </div>
</template>
<script>
export default {
}
</script>